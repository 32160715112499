import { Suspense } from 'react';
import '../styles/globals.css';
import React from 'react';
import { observer } from 'mobx-react';
import Head from 'next/head';
import { configure } from 'mobx';

import GlobalContextProvider from 'components/GlobalContextProvider';
import AuthListener from 'components/AuthListener';

configure({ enforceActions: 'never' });

const Loading = () => {
  return (
    <div className="w-full h-full bg-slate-900 flex justify-center items-center">
      <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>
    </div>
  );
};

const App = ({ Component, pageProps }) => {
  return (
    <AuthListener authRequired={Component.authRequired}>
      <GlobalContextProvider>
        <Head>
          <title>Fundky Host Panel</title>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/css2?family=Nova+Mono&display=swap"
          />
        </Head>
        <Suspense fallback={Loading()}>
          <Component {...pageProps} />
        </Suspense>
      </GlobalContextProvider>
    </AuthListener>
  );
};

export default observer(App);
