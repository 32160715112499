import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import SocketListener from 'components/SocketListener';
import GlobalContext from 'contexts';
import GlobalStore from 'store';

const store = new GlobalStore();

React.useLayoutEffect = React.useEffect;

const GlobalContextProvider = ({ children }) => {
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <GlobalContext.Provider value={store}>
      <SocketListener>{children}</SocketListener>
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(GlobalContextProvider);
