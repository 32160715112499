import { makeAutoObservable, toJS } from 'mobx';
import axios from 'axios';

import hash from 'object-hash';
import stringify from 'json-stable-stringify';

// Prevent recursive stringification
const filteredKeys = new Set([
  'rootStore',
  'lastReceivedHash',
  'updating',
  'plain',
  'stringified',
  'hash',
]);

class Store {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // Internal values
  lastReceivedHash = '';
  updating = false;
  campaign = {};

  translations = {
    en: {
      charity: {
        title: 'Charity Info',
        name: 'Virtual Guardians Foundation',
        info: [
          {
            label: 'Mission',
            description:
              'The foundation is an online beacon for people in distress, and works to promote healthy and responsible digital use. ',
          },
        ],
      },
      sponsors: {
        title: 'Sponsor Info',
        sponsors: [
          {
            name: 'Anime Ottawa',
            info: [
              'We are proud to announce our partnership with Anime Ottawa, come and visit our gaming zone on March 29th to 31st at the Ey Center in Ottawa!',
            ],
          },
          {
            name: 'Always Games',
            info: [
              "NoReset would like to thank Always Games, they're on site all weekend runing the Board games section. From Catan to Wingspan to Magic the Gathering, there will be something for everyone.",
            ],
          },
          {
            name: 'CIPC',
            info: [
              'Thank you CIPC for graciously providing us with gaming PCs that will be available for attendees during NoReset4!',
            ],
          },
        ],
      },
    },
    fr: {
      charity: {
        title: 'Info fondation',
        name: 'Fondation des Gardiens Virtuels',
        info: [
          {
            label: 'Mission',
            description:
              'La fondation est une balise en ligne pour les personnes en détresse et travaille à promouvoir l’utilisation saine et responsable du numérique.',
          },
        ],
      },
      sponsors: {
        title: 'Sponsor Info',
        sponsors: [
          {
            name: 'Anime Ottawa',
            info: [
              "Nous sommes fiers d'annoncer notre partenariat avec Anime Ottawa, venez visiter notre zone de gaming du 29 au 31 mars au Ey Centre à Ottawa!",
            ],
          },
          {
            name: 'Toujours Jeux',
            info: [
              'NoReset tient à remercier Toujours Jeux, ils sont sur place toute la fin de semaine pour animer la section Jeux de Table. De Catane à Wingspan en passant par Magic the Gathering, il y en aura pour tous les goûts.',
            ],
          },
          {
            name: 'CIPC',
            info: [
              'Merci à CIPC qui nous fournit gracieusement des PC de gaming qui seront à votre disposition lors de notre événement NoReset4!',
            ],
          },
        ],
      },
    },
  };

  onSite = 0.0;

  fundky = {
    id: 1358, // 421
    pollid: 77, // 54
    frameid: 80, // 55
    amount: 0.0,
    background: '',
    polls: [],
    milestones: [],
    donations: [],
    donationStatus: [],
    donationsHidden: [],
    donationsApproved: [],
    bidwarStatus: [],
    milestoneStatus: [],
    backgroundPoll: [],
    backgroundOptions: [],
    framePoll: [],
    frameOptions: [],
  };

  setDonations = amount => {
    this.onSite = amount;
  };

  getDonationTotal = () => {
    return this.fundky.amount + this.onSite;
  };

  setApproved = id => {
    if (!this.fundky.donationsApproved.find(e => e === id)) {
      this.fundky.donationsApproved.push(id);
      return;
    }
    this.fundky.donationsApproved = [...this.fundky.donationsApproved].filter(e => e !== id);
  };

  getApproved = id => {
    if (!this.fundky.donationsApproved.find(e => e === id)) {
      return false;
    }
    return true;
  };

  newCharityInfo = () => {
    const { info } = this.translations.en.charity;
    const { info: infoFR } = this.translations.fr.charity;
    let newItem = { label: 'New Charity Label', description: 'New Charity Info' };
    this.translations.en.charity.info = [...info, newItem];
    this.translations.fr.charity.info = [...infoFR, newItem];
  };

  removeCharityInfo = idx => {
    if (this.translations.en.charity.info.length === 0) return;
    const { info } = this.translations.en.charity;
    const { info: infoFR } = this.translations.fr.charity;
    this.translations.en.charity.info = [...info].filter((element, index) => index !== idx);
    this.translations.fr.charity.info = [...infoFR].filter((element, index) => index !== idx);
  };

  newSponsor = () => {
    const { sponsors } = this.translations.en.sponsors;
    const { sponsors: sponsorsFR } = this.translations.fr.sponsors;
    let newItem = { name: 'New Sponsor', info: ['New Sponsor Info'] };
    this.translations.en.sponsors.sponsors = [...sponsors, newItem];
    this.translations.fr.sponsors.sponsors = [...sponsorsFR, newItem];
  };

  removeSponsor = idx => {
    if (this.translations.en.sponsors.sponsors.length === 0) return;
    const { sponsors } = this.translations.en.sponsors;
    const { sponsors: sponsorsFR } = this.translations.fr.sponsors;
    this.translations.en.sponsors.sponsors = [...sponsors].filter(
      (element, index) => index !== idx,
    );
    this.translations.fr.sponsors.sponsors = [...sponsorsFR].filter(
      (element, index) => index !== idx,
    );
  };

  removeSponsorInfo = (idx, idx2) => {
    console.log('Hello Info IDX', idx);
    console.log('Hello Info IDX2', idx2);
    if (this.translations.en.sponsors.sponsors[idx].info.length === 0) return;
    const { info } = this.translations.en.sponsors.sponsors[idx];
    const { info: infoFR } = this.translations.fr.sponsors.sponsors[idx];
    this.translations.en.sponsors.sponsors[idx].info = [...info].filter(
      (element, index) => index !== idx2,
    );
    this.translations.fr.sponsors.sponsors[idx].info = [...infoFR].filter(
      (element, index) => index !== idx2,
    );
  };

  newSponsorInfo = idx => {
    const { info } = this.translations.en.sponsors.sponsors[idx];
    const { info: infoFR } = this.translations.fr.sponsors.sponsors[idx];
    this.translations.en.sponsors.sponsors[idx].info = [...info, `New Sponsor Info`];
    this.translations.fr.sponsors.sponsors[idx].info = [...infoFR, `New Sponsor Info`];
  };

  updateCharity = (key, val, lang, idx) => {
    if (key === 'name') this.translations[lang].charity.name = val;
    if (key === 'info') this.translations[lang].charity.info[idx].description = val;
    if (key === 'infoLabels') this.translations[lang].charity.info[idx].label = val;
  };

  updateSponsor = (key, val, lang, idx, idx2) => {
    if (key === 'name') this.translations[lang].sponsors.sponsors[idx].name = val;
    if (key === 'info') this.translations[lang].sponsors.sponsors[idx].info[idx2] = val;
  };

  getStatus = id => {
    if (!this.fundky.donationStatus.find(e => e === id)) {
      return false;
    }
    return true;
  };

  setStatus = id => {
    if (!this.fundky.donationStatus.find(e => e === id)) {
      this.fundky.donationStatus.push(id);
      return;
    }
    this.fundky.donationStatus = [...this.fundky.donationStatus].filter(e => e !== id);
  };

  getHidden = id => {
    if (!this.fundky.donationsHidden.find(e => e === id)) {
      return false;
    }
    return true;
  };

  setHidden = id => {
    if (!this.fundky.donationsHidden.find(e => e === id)) {
      this.fundky.donationsHidden.push(id);
      return;
    }
    this.fundky.donationsHidden = [...this.fundky.donationsHidden].filter(e => e !== id);
  };

  setFundkyID = id => (this.fundky.id = id);

  setBackgroundID = id => (this.fundky.pollid = id);

  setFrameID = id => (this.fundky.frameid = id);

  //fundky LIVE API url : https://fundky.live/en/api
  //fundky DEV API url : https://live.dev.fundky.com/en/api

  getPolls = async () => {
    try {
      let response = await axios.get(`/api/v1/polls/${this.fundky.id}`);
      if (response.status == 200)
        this.fundky.polls = await response.data.polls.filter(p => p.statusId);
    } catch (error) {
      this.fundky.polls = [];
      console.error("ERROR COULD'T FETCH POLLS", error.message);
    }
  };

  getAdmin = async id => {
    try {
      let { data } = await axios.get(`/api/v1/host/${id}`);
      return data.data;
    } catch (error) {
      return false;
    }
  };

  getTotalRaised = async () => {
    try {
      let response = await axios.get(`/api/v1/donations/total/${this.fundky.id}`);
      if (response.status == 200) this.fundky.amount = response.data.current;
    } catch (error) {
      this.fundky.amount = 0.0;
      console.error("ERROR COULD'T FETCH TOTAL RAISED --- ", error.message);
    }
  };

  getMilestones = async () => {
    try {
      let response = await axios.get(`/api/v1/milestones/${this.fundky.id}`);
      if (response.status == 200) this.fundky.milestones = response.data.milestones; //.filter(m => m.amount > this.fundky.amount);
    } catch (error) {
      this.fundky.milestones = [];
      console.error("ERROR COULD'T FETCH MILESTONES --- ", error.message);
    }
  };

  getDonationList = async () => {
    try {
      let response = await axios.get(`/api/v1/donations/list/${this.fundky.id}`);
      if (response.status === 200) this.fundky.donations = response.data.donations;
    } catch (error) {
      this.fundky.donations = [];
      console.log("ERROR COULD'T FETCH DONATION LIST", error.message);
    }
  };

  get jsonString() {
    return JSON.stringify(toJS(this));
  }

  get plain() {
    return toJS(this);
  }

  get stringified() {
    return stringify(this.plain, {
      replacer: (k, v) => {
        if (filteredKeys.has(k)) {
          // Remove it from the output
          return undefined;
        }

        return v;
      },
    });
  }

  get hash() {
    return hash(this.stringified);
  }
}

export default Store;
